import React from 'react';
import { NavLink } from 'react-router-dom';

import './Service.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

const Service = () => {
  return (
    <div className='service-div'>
      <div className='hours-header-div'>
        <h2 className='service-header'>Location</h2>
      </div>
      <h3 className='time-header'>96 Victoria St W Unit 4, Alliston, ON L9R 1S6</h3>
      <div className='location-wrapper'>
        <div className='location-div'>
          <p className='home-body-content'>
            Our floral design shop, also featuring plants and accessories, is
            situated in the heart of historical downtown Alliston, Ontario.
          </p>
        </div>
        <div className='map-div'>
          <iframe
            className='google-map'
            title='map'
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2862.5803813176285!2d-79.87100678399263!3d44.15389597910714!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882aef23b1e60d19%3A0x9641fdeb4cadd78c!2sNorthern%20Blush%20Floral!5e0!3m2!1sen!2sca!4v1610716863252!5m2!1sen!2sca'
            frameborder='0'
            allowfullscreen=''
            aria-hidden='false'
            tabindex='0'
          ></iframe>
        </div>
      </div>

      <div className='hours-of-operation-div'>
        <div className='hours-header-div'>
          <h2 className='service-header'>Hours of Operation</h2>
        </div>
        <h3 className='time-header'>Tuesday - Friday 10am - 6pm <br/> Saturday 12pm - 4pm</h3>

        <div className='hours-of-operation-body'>
          <p className='home-body-content'>
            Some Saturdays we may close due to events and weddings,
            please check Instagram for updates. We provide floral delivery
            Tuesday through Saturday, hours based on availability.{' '}
          </p>
          <div className='home-body-content service-contact' style={{ paddingTop: '15px' }}>
            <div className='service-contact-section'>
              <div className="service-contact-icon">
                <FontAwesomeIcon size="1x" icon={faEnvelope} /> 
              </div>
              <div className="service-contact-text">
                northernblushfloral@gmail.com
              </div>
            </div>
            <div className='service-contact-section'>
              <div className="service-contact-icon">
                <FontAwesomeIcon size="1x" icon={faPhone} /> 
              </div>
              <div className="service-contact-text">
                647-678-2248
              </div>
            </div>
          </div>
        </div>
        <div className='service-button-div'>
          <NavLink to='/order'>
            <button className='btn service-button-btn'>Order Flowers</button>
          </NavLink>
          <NavLink to='/weddings'>
            <button className='btn service-button-btn'>Make a Wedding Inquiry</button>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Service;
