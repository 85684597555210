import React from 'react';

// Images
import Logo from '../../../assets/images/full_logo_white_outline.png';

import './Logo.css';

const Arrow = () => {
  return (
    <div className='logo-div'>
      <img src={Logo} alt='logo' />
    </div>
  );
};

export default Arrow;
