/** @jsx jsx */
import { css, jsx } from '@emotion/core'

const Slide = ({ content, width, slide_index }) => (
  <div
    css={css`
      height: 100%;
      width: ${width}px;
      background-image: url('${content}');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    `}
  >
      {slide_index == 0 && 
        <div
          css={css`
            position: absolute;
            bottom: 0;
            background-color: var(--clr-dark);
            color: var(--clr-accent-two);
            padding: 0px 2px;
          `}
        > 
          @taylorannephotographs
        </div>
      }
  </div>
)

export default Slide
