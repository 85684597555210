import React from 'react';

// Components
import Slider from './NewSlideShow/Slider';
import Service from './Service';

// Slide Images
import SlideshowImg1 from '../../assets/images/slideshow1.jpg';
import SlideshowImg2 from '../../assets/images/slideshow2.jpg';
import SlideshowImg3 from '../../assets/images/slideshow3.jpg';
import SlideshowImg4 from '../../assets/images/slideshow4.jpg';
import SlideshowImg5 from '../../assets/images/slideshow5.jpg';
import SlideshowImg6 from '../../assets/images/slideshow6.jpg';
import SlideshowImg7 from '../../assets/images/slideshow7.jpg';
import SlideshowImg8 from '../../assets/images/slideshow8.jpg';
import SlideshowImg9 from '../../assets/images/slideshow9.jpg';
import SlideshowImg10 from '../../assets/images/slideshow10.jpg';

import './Home.css';

const Home = () => {
  const images = [
    SlideshowImg9,
    SlideshowImg8,
    SlideshowImg1,
    SlideshowImg2,
    SlideshowImg3,
    SlideshowImg4,
    SlideshowImg5,
    SlideshowImg6,
    SlideshowImg7,
    SlideshowImg10
  ];

  return (
    <>
      <Slider slides={images} />
      <Service />
    </>
  );
};

export default Home;
