import React from 'react';

import './Footer.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';


const Footer = (props) => {
  return (
    <footer className='footer'>
      <ul className='social-list'>
        <li className='social-list__item'>
          <a
            className='social-list__link'
            href='mailto:northernblushfloral@gmail.com'
          >
            <FontAwesomeIcon size="2x" icon={faEnvelope} />
          </a>
        </li>
        <li className='social-list__item'>
          <a
            className='social-list__link'
            href='https://www.instagram.com/NorthernBlushFloral/'
          >
            <FontAwesomeIcon size="2x" icon={faInstagram} />
          </a>
        </li>
        <li className='social-list__item'>
          <a
            className='social-list__link'
            href='https://www.facebook.com/northernblushfloral'
          >
            <FontAwesomeIcon size="2x" icon={faFacebook} />
          </a>
        </li>
      </ul>
      <p className='copyright'>
        Copyright &copy; 2020 Northern Blush Floral all rights reserved.
      </p>
    </footer>
  );
};

export default Footer;
