import React from 'react';

// Images
import NewWeddingImg from '../../../assets/images/weddingsnew.JPG';

import WeddingForm from './WeddingForm';

import './WeddingInquiry.css';

const Weddings = () => {
  return (
    <section className='wedding-inquiry-div'>
      <div className='wedding-inquiry-img-div'>
        <img className='wedding-inquiry-img' src={NewWeddingImg} alt='wedding-img' />
      </div>

      <div className='wedding-inquiry-content-div'>      
      <div >
        <p>
          We at Northern Blush Floral aspire to form harmonious relationships,
          that allow us to arrange truly what you've envisioned and have been
          inspired by to create your ideal florals for any special occasion.
        </p>
        <p>
          To request a quote or to book a consultation, email us northernblushfloral@gmail.com, call us at 647 678 2248, or fill the form below.
        </p>

      </div>
      <WeddingForm /></div>

    </section>
  );
};

export default Weddings;
