import React, { useState, useEffect } from 'react';

import {
  FormControl,
  TextField,
  Select,
  InputLabel,
  FormHelperText,
  makeStyles,
} from '@material-ui/core';

import TACTIC from '../../tactic/Tactic';

// Images
import order_img from '../../assets/images/order_img.jpg';

import './Order.css';

export default function Sympathy() {
  return (
    <section className='order-div'>
      <div className='order-img-div'>
        <img className="order-img" src={order_img} alt='order-img' />
      </div>
      <div className='order-form-div'>
        <OrderForm />
      </div>
    </section>
  );
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
    },
    '& .MuiSelect-root': {
      margin: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
    },
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
}));

function getNextValidDateString() {
  let date = new Date();
  if (date.getDay() == 6) {
    // Saturday - shift to Tuesday
    date.setDate(date.getDate() + 3); 
  } else if (date.getDay() == 0) {
    // Sunday - shift to Tuesday
    date.setDate(date.getDate() + 2); 
  } else {
    // all other days - shift to tomorrow
    date.setDate(date.getDate() + 1);    
  }

  // Get year, month, and day part from the date
  let year = date.toLocaleString("default", { year: "numeric" });
  let month = date.toLocaleString("default", { month: "2-digit" });
  let day = date.toLocaleString("default", { day: "2-digit" });

  // Generate yyyy-mm-dd date string
  let formattedDate = year + "-" + month + "-" + day;
  return formattedDate;
};

function OrderForm() {
  const classes = useStyles();

  const formElements = {
    order_type: {
      label: 'Arrangement Type',
      component: Select,
      options: [
        { value: 'wreath', label: 'Wreath' },
        { value: 'spray', label: 'Spray' },
        { value: 'basket', label: 'Basket' },
        { value: 'bouquet', label: 'Bouquet' },
      ],
      defaultValue: 'wreath',
    },
    first_name: {
      label: 'Your First Name',
      component: TextField,
      required: true,
    },
    last_name: {
      label: 'Your Last Name',
      component: TextField,
      required: true,
    },
    phone_number: {
      label: 'Your Phone Number',
      component: TextField,
      required: true,
    },
    email: {
      label: 'Your Email',
      component: TextField,
      required: true,
    },
    preferredContactMethod: {
      label: "Preferred Contact Method",
      component: Select,
      options: [
        { value: 'phone', label: 'Phone call' },
        { value: 'email', label: 'Email' },
        { value: 'text', label: 'Text message' },
      ],
      defaultValue: 'phone',
    },
    order_date: {
      label: "Select delivery date",
      component: TextField,
      type: "date",
      defaultValue: getNextValidDateString(),
    },
    price_point: {
      label: 'Price Point',
      component: Select,
      options: [
        { value: '75', label: '$75' },
        { value: '95', label: '$95' },
        { value: '125', label: '$125' },
        { value: '150', label: '$150' },
        { value: '200', label: '$200+' }
      ],
      defaultValue: '75',
    },
    deceased_name: {
      label: 'Deceased Name',
      component: TextField,
      required: true,
    },
    funeral_home: {
      label: 'Funeral Home',
      component: TextField,
      required: true,
    },
    delivery_address: {
      label: 'Delivery Address',
      component: TextField,
      required: true,
    },
    card_message: {
      label: 'Card Message',
      component: TextField,
    },
    special_instructions: {
      label: 'Special Instructions',
      component: TextField,
    },
  };

  let fieldsObj = {};
  Object.keys(formElements).forEach((name) => {
    let config = formElements[name];
    if (config.defaultValue) fieldsObj[name] = config.defaultValue;
    else fieldsObj[name] = '';
  });
  const [values, setValues] = useState(fieldsObj);

  let errorsObj = {};
  Object.keys(formElements).forEach((name) => (errorsObj[name] = ''));
  const [errors, setErrors] = useState(errorsObj);

  const [complete, setComplete] = useState(false);
  const [serverError, setServerError] = useState(false);

  const [disabled, setDisabled] = useState(false);

  const validateForm = () => {
    // TODO
    return true;
  };

  const handleChangeForm = (name, value) => {
    setValues({ ...values, [name]: value });
  };

  function handleSubmit() {
    let kwargs =  encodeURIComponent(JSON.stringify({ data: values }));
    let server = new TACTIC();
    let endpoint = server.getEndpoint('/floralapi/v1');
    let url = endpoint + '?method=order&kwargs=' + kwargs;
    fetch(url)
      .then((resp) => resp.json())
      .then((ret_val) => {
        if (!ret_val) setComplete(true);
        else if (ret_val.errors) {
          setErrors(ret_val.errors);
        }
      })
      .catch(() => {
        setServerError(true);
      });
  }

  function getFormControl(name, config) {
    let Component = config.component || TextField;

    let onChange = (evt) => {
      if (config.validate) {
        if  (config.validate(evt.target.value)) {
          handleChangeForm(name, evt.target.value);
        } else {
          config.handleInvalid(name, evt.target.value, errors, setErrors)
        }
      } else {
        handleChangeForm(name, evt.target.value);
      }
    }

    if (Component === TextField) {
      return (
        <FormControl
          key={name}
          fullWidth={true}
          error={Boolean(errors[name])}
          className={classes.formControl}
        >
          <Component
            required={config.required}
            value={values[name]}
            type={config.type}
            id={name}
            onChange={onChange}
            label={config.label}
            autoFocus={config.autoFocus}
          />
          <FormHelperText id={name + '-form-helper-text'}>
            {errors[name]}
          </FormHelperText>
        </FormControl>
      );
    } else if (Component === Select) {
      return (
        <FormControl
          key={name}
          fullWidth={true}
          error={Boolean(errors[name])}
          className={classes.formControl}
        >
          <InputLabel htmlFor={name}>{config.label}</InputLabel>
          <Select
            native
            required={config.required}
            value={values[name]}
            type={config.type}
            id={name}
            onChange={(evt) => handleChangeForm(name, evt.target.value)}
            autoFocus={config.autoFocus}
          >
            {config.options.map((item) => {
              return <option value={item.value}>{item.label}</option>;
            })}
          </Select>
          <FormHelperText id={name + '-form-helper-text'}>
            {errors[name]}
          </FormHelperText>
        </FormControl>
      );
    }
  }

  if (complete) {
    return (
      <div className='confirmation-div'>
        <h2>We have received your order. We will contact you for payment.</h2>
      </div>
    );
  } else if (serverError) {
    return (
      <div className='confirmation-div'>
        <h2>There was an error processing your order. Please call us directly - 647 678 2248</h2>
      </div>
    );
  } else {
    return (
      <>
        <div className='order-header-div'>
          <h2>Funeral Flower Arrangement Order Form</h2>
        </div>
        {Object.keys(formElements).map((item, key) => {
          return getFormControl(item, formElements[item], key);
        })}
        <div className='order-button-div'>
          <button className='btn' disabled={!validateForm || disabled} onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </>
    );
  }
}
