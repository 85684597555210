import React from 'react';

import './WeddingGallery.css';

import { useParams } from 'react-router-dom';

const alexMihanImages = require.context('../../../assets/images/weddings/alexmihan', true);
const alexMihanImageList = alexMihanImages.keys().map(image => alexMihanImages(image));

const joeAndBethImages = require.context('../../../assets/images/weddings/joe_and_beth', true);
const joeAndBethImageList = joeAndBethImages.keys().map(image => joeAndBethImages(image));
 
const melanieAndDanielImages = require.context('../../../assets/images/weddings/melanie_and_daniel', true);
const melanieAndDanielImageList = melanieAndDanielImages.keys().map(image => melanieAndDanielImages(image));
 
const ginAndJacImages = require.context('../../../assets/images/weddings/gin_and_jac', true);
const ginAndJacImageList = ginAndJacImages.keys().map(image => ginAndJacImages(image));

const amandaAndBryanImages = require.context('../../../assets/images/weddings/amanda_and_bryan', true);
const amandaAndBryanImageList = amandaAndBryanImages.keys().map(image => amandaAndBryanImages(image));

const weddings_data = {
    "joe_and_beth": {
      "title": "Joe + Beth",
      "photographer_credit": "@loriwaltenburyphoto",
      "images": joeAndBethImageList
    },
    "alexmihan": {
      "title": "Alex + Kyle",
      "photographer_credit": "@whimandwillow",
      "images": alexMihanImageList
    },
    "melanie_and_daniel": {
      "title": "Melanie + Daniel",
      "photographer_credit": "@taylorannephotographs",
        "images": melanieAndDanielImageList
    },
    "gin_and_jac": {
      "title": "Gin + Jac",
      "photographer_credit": "@taylorannephotographs",
    "images": ginAndJacImageList
    },
    "amanda_and_bryan": {
      "title": "Amanda + Bryan",
      "photographer_credit": "@abalistphoto",
      "images": amandaAndBryanImageList
    }
  }


const WeddingGallery = () => {
  
  const { name } = useParams();

  const { title, photographer_credit, images } = weddings_data[name];

  return (
    <section>
      <h3 className="wedding-title">{title}</h3>
      <p className="wedding-title">Photography by {photographer_credit}</p>
      <div className="wedding-gallery">
        {images.map((image, index) => (
          <div key={index} className="wedding-img-container">
            <img className="wedding-img" src={image} alt={`Wedding ${index}`} />
          </div>
        ))}
      </div>
    </section>
  );
};

export default WeddingGallery;






