import React from 'react';

import { NavLink } from 'react-router-dom';

import './Weddings.css';

import WeddingThumbnail from './WeddingThumbnail';

const alexMihanImages = require.context('../../../assets/images/weddings/alexmihan', true);
const alexMihanImageList = alexMihanImages.keys().map(image => alexMihanImages(image));

const joeAndBethImages = require.context('../../../assets/images/weddings/joe_and_beth', true);
const joeAndBethImageList = joeAndBethImages.keys().map(image => joeAndBethImages(image));
 
const melanieAndDanielImages = require.context('../../../assets/images/weddings/melanie_and_daniel', true);
const melanieAndDanielImageList = melanieAndDanielImages.keys().map(image => melanieAndDanielImages(image));
 
const ginAndJacImages = require.context('../../../assets/images/weddings/gin_and_jac', true);
const ginAndJacImageList = ginAndJacImages.keys().map(image => ginAndJacImages(image));

const amandaAndBryanImages = require.context('../../../assets/images/weddings/amanda_and_bryan', true);
const amandaAndBryanImageList = amandaAndBryanImages.keys().map(image => amandaAndBryanImages(image));

const weddings_data = {
    "joe_and_beth": {
      "title": "Joe + Beth",
      "photographer_credit": "@loriwaltenburyphoto",
      "images": joeAndBethImageList
    },
    "alexmihan": {
      "title": "Alex + Kyle",
      "photographer_credit": "@whimandwillow",
      "images": alexMihanImageList
    },
    "melanie_and_daniel": {
      "title": "Melanie + Daniel",
      "photographer_credit": "@taylorannephotographs",
        "images": melanieAndDanielImageList
    },
    "gin_and_jac": {
      "title": "Gin + Jac",
      "photographer_credit": "@taylorannephotographs",
    "images": ginAndJacImageList
    },
    "amanda_and_bryan": {
      "title": "Amanda + Bryan",
      "photographer_credit": "@abalistphoto",
      "images": amandaAndBryanImageList
    }
  }




const WeddingsLanding = () => {
  return (
    <section className='weddings-div'>
      <div className='weddings-content-div'>      
        <div>
          <p>
            We at Northern Blush Floral aspire to form harmonious relationships,
            that allow us to arrange truly what you've envisioned and have been
            inspired by to create your ideal florals for any special occasion.
          </p>
          <p>
            <NavLink to='/weddings'>
              <button className='btn service-button-btn'>Make a Wedding Inquiry</button>
            </NavLink>
          </p>
        </div>
        <div class="wedding-thumbnail-gallery">
          {Object.entries(weddings_data).map(([name, gallery]) => (
            <WeddingThumbnail 
              key={name}
              name={name}
              title={gallery.title}
              photographer_credit={gallery.photographer_credit}
              images={gallery.images}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default WeddingsLanding;
