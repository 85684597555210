
import React, { useState, useEffect } from 'react';

import {
  FormControl,
  TextField,
  Select,
  InputLabel,
  FormHelperText,
  makeStyles,
} from '@material-ui/core';


  export function getFormControl(name, config, value, error, handleChangeForm, handleKeyPress, classes) {
    let Component = config.component || TextField;
    if (Component === TextField) {
      return (
        <FormControl
          key={name}
          fullWidth={true}
          error={Boolean(error)}
          className={classes.formControl}
        >
          <Component
            required={config.required}
            value={value}
            type={config.type}
            id={name}
            onChange={(evt) => handleChangeForm(name, evt.target.value)}
            label={config.label}
            autoFocus={config.autoFocus}
            onKeyPress={handleKeyPress}
            multiline={config.multiline || false}
            rows={config.rows || 1}
          />
          <FormHelperText id={name + '-form-helper-text'}>
            {error}
          </FormHelperText>
        </FormControl>
      );
    } else if (Component === Select) {
      return (
        <FormControl
          key={name}
          fullWidth={true}
          error={Boolean(error)}
          className={classes.formControl}
        >
          <InputLabel htmlFor={name}>{config.label}</InputLabel>
          <Select
            native
            required={config.required}
            value={value}
            type={config.type}
            id={name}
            onChange={(evt) => handleChangeForm(name, evt.target.value)}
            autoFocus={config.autoFocus}
          >
            {config.options.map((item) => {
              return <option value={item.value}>{item.label}</option>;
            })}
          </Select>
          <FormHelperText id={name + '-form-helper-text'}>
            {error}
          </FormHelperText>
        </FormControl>
      );
    }
  }