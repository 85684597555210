import React from 'react';
import { NavLink } from 'react-router-dom';
import './WeddingThumbnail.css';


const WeddingThumbnail = ({ name, title, photographer_credit, images }) => {

    return (
      <div className='wedding-thumbnail card'>
        <NavLink to={`/wedding_gallery/${name}`} style={{ textDecoration: 'none' }}>
            <img src={images[0]} alt={title} style={{ aspectRatio: '3 / 2', objectFit: 'cover' }} />
            <div className="thumbnail-caption">
                {title}
            </div>
        </NavLink>
      </div>
    );
};

export default WeddingThumbnail;    