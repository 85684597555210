export default class TACTIC {

  constructor() {
      this.server_url = process.env.REACT_APP_TACTIC_SERVER_URL;
      this.site = process.env.REACT_APP_TACTIC_SITE;
      this.project = process.env.REACT_APP_TACTIC_PROJECT;
      
      // For fetch from npm test server
      this.cors = process.env.REACT_APP_TACTIC_CORS;
  }

  getEndpoint(suffix) {
    let url = this.server_url;
    
    if (this.site && this.site !== "default") url += "/" + this.site;
    else url += "/tactic";
      
    url += "/" + this.project 
    
    url += "/" + suffix;

    return url
  }

}
