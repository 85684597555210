import React from 'react';

// Images
import about_img from '../../assets/images/about_img.jpeg';

import './About.css';

const About = () => {
  return (
    <section className='about-div'>
      <div className='about-img-div'>
        <img
          className='about-img'
          src={about_img}
          alt='about-img'
        />
      </div>
      <div className='about-content-div'>
        <p>
          Northern Blush Floral couples an affection for plant life with a flair
          for floral ingenuity.
        </p>

        <p>
          Our blooms are sourced as often as possible from small growing
          operations based in Ontario. Despite our core objective of being as
          sustainable as possible while encouraging buying locally, we also
          integrate imported floral varieties into our designs. Our methods
          grant Northern Blush the creative freedom to grow with the seasons.
        </p>

        <p>
          Having numerous years of experience immersed in the floral and events
          industry as a designer in Toronto, Ontario our team at Northern Blush
          Floral can provide individuals with beautiful work suitable for any
          client or fitting occasion. Specializing in providing locally sourced
          fresh weekly and biweekly standing orders, weddings near and far,
          events, staging and your everyday floral and botanical needs.
        </p>
      </div>
    </section>
  );
};

export default About;
