import React, { useState, useEffect } from 'react';

import {
  FormControl,
  TextField,
  Select,
  InputLabel,
  FormHelperText,
  makeStyles,
} from '@material-ui/core';

import TACTIC from '../../../tactic/Tactic';

// Images
import order_img from '../../../assets/images/order_img.jpg';

import '../Order.css';

import { getFormControl } from '../form_helper';


const useStyles = makeStyles((theme) => ({
  formControl: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
    },
    '& .MuiSelect-root': {
      margin: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
    },
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
}));

export default function WeddingForm() {

  const classes = useStyles();

  const formElements = {
    name: {
      label: 'Name',
      component: TextField,
      required: true,
    },
    phone_number: {
      label: 'Phone Number',
      component: TextField,
      required: true,
    },
    email: {
      label: 'Email',
      component: TextField,
      required: true,
    },
    date: {
      label: 'Planned wedding date (mm-dd-yyyy)',
      component: TextField,
    },
    location: {
      label: 'Location (Address, Venue, City)',
      component: TextField,
      required: true,
    },
    num_wedding_party: {
      label: 'Size of wedding party',
      component: TextField,
      required: true,
      type: 'number'
    },
    num_tables: {
      label: 'Number of Tables',
      component: TextField,
      required: true,
      type: 'number'
    },
    notes: {
      label: 'Notes',
      component: TextField,
      required: false,
      rows: 2,
      multiline: true
    },
  };

  let fieldsObj = {};
  Object.keys(formElements).forEach((name) => {
    let config = formElements[name];
    if (config.defaultValue) fieldsObj[name] = config.defaultValue;
    else fieldsObj[name] = '';
  });
  const [values, setValues] = useState(fieldsObj);

  let errorsObj = {};
  Object.keys(formElements).forEach((name) => (errorsObj[name] = ''));
  const [errors, setErrors] = useState(errorsObj);

  const [complete, setComplete] = useState(false);
  const [serverError, setServerError] = useState(false);

  const [disabled, setDisabled] = useState(false);

  const handleKeyPress = (evt) => {
    //
  };

  const validateForm = () => {
    // TODO
    return true;
  };

  const handleChangeForm = (name, value) => {
    setValues({ ...values, [name]: value });
  };

  function handleSubmit() {
    let kwargs =  encodeURIComponent(JSON.stringify({ data: values }));
    let server = new TACTIC();
    let endpoint = server.getEndpoint('/floralapi/v1');
    let url = endpoint + '?method=wedding&kwargs=' + kwargs;
    fetch(url)
      .then((resp) => resp.json())
      .then((ret_val) => {
        if (!ret_val) setComplete(true);
        else if (ret_val.errors) {
          setErrors(ret_val.errors);
        }
      })
      .catch(() => {
        setServerError(true);
      });
  }


  if (complete) {
    return (
      <div className='confirmation-div'>
        <h2>We have received your request, and will contact you shortly.</h2>
      </div>
    );
  } else if (serverError) {
    return (
      <div className='confirmation-div'>
        <h2>There was an error processing your request. Please call or email us directly.</h2>
      </div>
    );
  } else {
    return (
      <>
        {Object.keys(formElements).map((item, key) => {
          let name = item;
          let config = formElements[item];
          let value =  values[item];
          let error = errors[item];
          return getFormControl(name, config, value, error, handleChangeForm, handleKeyPress, classes);
        })}
        <div className='order-button-div'>
        <button className='btn' disabled={!validateForm || disabled} onClick={handleSubmit}>
          Submit
        </button>
        </div>
      </>
    );
  }
}
